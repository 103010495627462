.StudiosWrap {
	padding: 0 13rem !important;
	.SwiperContent {
		img {
			width: 100%;
		}
	}
	.gallery-thumbs {
		width: 600px !important;
		overflow: hidden;
		img {
			width: 100%;
		}
	}
	.swiper-container {
		margin-left: auto;
		margin-right: auto;
		overflow: hidden;

		.startInFirst {
			transform: translate3d(0, 0, 0) !important;
		}
	}
	.gallery-thumbs .swiper-slide {
		background-size: cover;
		background-position: center;
	}
	.gallery-top {
		height: 80%;
		width: 600px;
	}
	.gallery-thumbs {
		width: 80%;
		height: 200px;
		box-sizing: border-box;
		padding: 10px 0;
	}
	.gallery-thumbs .swiper-slide {
		width: 25%;
		height: 100%;
		opacity: 0.9;
	}
	.gallery-thumbs .swiper-slide-active {
		opacity: 1;
	}
	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:hover {
		color: #fc5869 !important;
		// padding: 0 8px;
		font-weight: bold;
		text-align: center;
	}
	.ant-tabs-tab:hover {
		color: #fc5869 !important;
	}
	.topTab > .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list div:nth-child(2) > div::before {
		content: '|';
		color: #000;
		margin: 0 8px;
	}
	.ant-tabs-ink-bar {
		display: none !important;
	}
	.ant-tabs {
		display: unset;
	}
	.ant-tabs-tab {
		font-size: 20px;
		color: #777777;
		font-weight: bold;
	}
	.ant-tabs-bar {
		border-right: 0;
		margin-left: 10px;
		.ant-tabs-tab {
			border-right: 1px solid #cccccc;
			padding: 0 !important;
			// font-weight: bold;
			margin: 20px 10px 10px 0 !important;
			color: rgba(119, 119, 119, 1);
		}
		.ant-tabs-tab:first-child {
			padding-right: 10px !important;
		}
		.ant-tabs-tab:last-child {
			border: 0;
		}
		.ant-tabs-nav .ant-tabs-tab-active {
			color: #fc5869;
			border-bottom: 2px solid #fc5869;
		}
		.ant-tabs-tab:hover {
			color: #fc5869;
		}
	}
	.swiper-button,
	.swiper-button-prev,
	.swiper-button-next {
		background-color: #fc5869;
		background-size: 50%;
		color: #ffffff;
	}
	.swiper-button-prev:after,
	.swiper-button-next:after,
	.swiper-button::after {
		font-size: 20px;
	}
	.cityVideos:hover {
		cursor: pointer;
	}
	.swiper-container-horizontal {
		height: 100px;
	}
	.cityVideos {
		height: 100px;
		text-align: center;
		position: relative;
		img {
			width: 95%;
		}
		span {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: rgba(0, 0, 0, .5);
			color: #ffffff;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	.StudiosWrap-title {
		text-align: center;
		margin-left: -10px !important;
		width: 25%;
		dt {
			margin: 0 10px !important;
			position: relative;
			height: 150px;
			img {
				width: 100%;
				height: 150px;
				max-width: 100%;
				max-height: 100%;
			}
			p {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background: rgba(0, 0, 0, .5);
				color: #ffffff;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
	.swiper-slide-box {
		text-align: center;
	}
	.StudiosWrap-content {
		.StudiosWrap-content-title {
			margin-top: 30px;
			text-align: center !important;
			// font-size: 24px;
			font-size: 20px;
			font-weight: bold;
			color: rgba(51, 51, 51, 1);
			margin-bottom: 20px;
		}
	}
	.StudiosWrap-content-wrap {
		display: flex;
		.content-left {
			width: 60%;
			min-width: 600px;
			margin-right: 20px;
			overflow: hidden;
			.swiper-slide {
				width: auto !important;
			}
			ol {
				li {
					margin: 5px 0 !important;
					p {
						padding-left: 5px !important;
						border-left: 3px solid #fc5869;
					}
					span {
						color: rgba(171, 175, 187, 1);
					}
				}
				li:last-child {
					display: flex;
					flex-direction: column;
					em {
						font-style: normal;
					}
				}
			}
			.StudiosWrap-pic {
				margin-top: 20px;
				div {
					margin: 0 5px;
				}
			}
			.StudiosWrap-list {
				p,
				p {
					img {
						width: 100%;
						height: 386px;
					}
				}
			}
		}
		.content-right-text {
			ol {
				li {
					p {
						padding-left: 5px;
						border-left: 3px solid #fc5869;
						color: #333333;
						font-size: 14px;
						font-weight: bold;
					}
					span {
						span {
							margin-right: 5px;
						}
						color: rgba(171, 175, 187, 1);
					}
					em {
						font-style: normal;
						color: #222222;
						font-weight: 900;
						font-size: 12px;
					}
				}
				li:last-child {
					display: flex;
					flex-direction: column;
					color: #777777;
					font-size: 12px;
					em {
						display: block;
						font-style: normal;
					}
				}
			}
		}
	}
	.content-list {
		margin-top: 30px;
		h6 {
			border-left: 3px solid #fc5869;
			padding-left: 5px !important;
			font-size: 15px;
			font-weight: bold;
		}
		.content-list-count {
			ul {
				display: grid;
				grid-template-columns: auto auto auto;
				li:nth-child(1),
				li:nth-child(2),
				li:nth-child(3) {
					p {
						color: #fc5869;
						border: 1px solid #fc5869;
					}
				}
				li {
					margin: 10px !important;
					display: flex;
					flex-direction: column;
					display: inline-block;
					vertical-align: top;
					// width: 30%;
					p {
						width: fit-content;
						color: #389afc;
						border: 1px solid #389afc;
						padding: 5px !important;
						border-radius: 2px;
						// font-size: 16px;
						font-size: 12px;
						display: inline-block;
					}
					div {
						// white-space: nowrap;
					}
					span {
						width: 100%;
						color: #777777;
						// font-size: 16px;
						// white-space: nowrap;
						font-size: 12px;
						margin-right: 10px;
						display: inline-block !important;
					}
				}
			}
		}
	}
	.SpecialEffects-Content {
		p {
			font-weight: bold;
		}
		.Special-content-title {
			padding: 30px !important;
			background: url("../../assets/images/tx-title-bg.png")no-repeat;
			color: #ffffff;
		}
		.ant-tabs-tab{
			margin: 0 5px!important;
		}
		.ant-tabs-nav-operations{
			display: none;
		}
		.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list{
			display: block!important;
			margin: 0 auto;
		}
		.ant-tabs-nav {
			width: 100%;
			// display: unset!important;
			div {
				text-align: center;
			}
		}

		.Special-content-tabs {

			p {
				text-align: center;
				font-size: 28px;
			}
			dl {
				dt {
					height: 640px;
					img {
						width: 100%;
						height: 100%;
					}
				}
				dd {
					h5 {
						font-size: 20px;
						margin-top: 15px !important;
					}
					p {
						font-size: 17px;
						color: #777777;
						text-align: left;
					}
				}
			}
		}
		.YT-banner {
			width: 100%;
			img {
				width: 100%;
				height: auto;
				max-width: 100%;
				max-height: 100%;
			}
		}
	}
	.AddressWrap {
		margin-bottom: 30px !important;
		font-weight: bold;
		#allmap {
			height: 300px;
		}
		p {
			padding-left: 5px !important;
			border-left: 3px solid #fc5869;
		}
		.BMap_bubble_pop {
			//left: 200px!important;
			width: 300px !important;
			border: 0 !important;
			background: #ffffff;
			border-radius: 10px;
			padding-top: 5px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.BMap_bubble_title {
				background-color: #ffffff !important;
				color: #222222;
				// font-size: 20px;
				font-size: 16px;
			}
			.BMap_bubble_buttons {
				div:nth-child(2) {
					background-color: #ffffff !important;
					border-radius: 10px;
				}
			}
			.BMap_bubble_content {
				color: #abafbb;
				font-size: 13px;
			}
			.BMap_bubble_top {
				border-radius: 10px !important;
				border: 0 !important;
				background-color: #ffffff !important;
				font-size: 16px;
				font-weight: 900;
			}
			img {
				display: none;
			}
		}
	}
}
@media screen and (max-width: 1300px) {

	.StudiosWrap {
		padding: 0 10px !important;
	}
}

@media screen and (max-width: 750px) {
	.slider-btn-S{
		display: none!important;
	}
	.slick-list	.slick-img-box{
		width: 29vw!important;
	}
	.Special-content-tabs {
		dt {
			height: 250px !important;
		}
	}
	.StudiosWrap {
		padding: 0 5px !important;
		.StudiosWrap-content-wrap {
			display: flow-root;
		}
		.ant-tabs {
			display: block!important;
		}
		.SpecialEffects-Content {
			.Special-content-title {
				background: url("../../assets/images/m-title-bg.png")no-repeat;
				background-size: 100% 100%;
			}
		}
		.content-list-count {
			ul {
				grid-template-columns: auto !important;
			}
		}
		.YT-banner {
			height: 185px !important;
		}
		.content-left {
			width: 100% !important;
			min-width: 100% !important;
			margin-bottom: 30px;
			div {
				p {
					text-align: left !important;
				}
			}
		}
		.StudiosWrap .StudiosWrap-content-wrap .content-left {
			width: 100% !important;
			min-width: 100% !important;
			.M-img {
				height: 200px;
			}
		}
		.content-right-text {
			margin: 0 10px;
		}
		.content-list {
			margin: 20px 10px;
		}
		.StudiosWrap-pic {
			margin: 0 !important;
		}
		.StudiosWrap-list {
			.StudiosWrap-pic {
				display: none;
			}
		}
		.swiper-button,
		.m-hide {
			display: none !important;
		}
		.swiper-container-horizontal {
			height: auto !important;
		}
		.swiper-button-next,
		.swiper-button-prev {
			display: none;
		}
		.AddressWrap {
			margin: 0 10px;
			.BMap_bubble_pop {
				left: 40px !important;
			}
		}
	}
}

.slider-btn-S{
	background: #fc5869;
	position: absolute;
	top: 35px;
	color: #fff;
	width: 20px;
	line-height: 30px;
	text-align: center;
}
.left-slider-btn-S{
	left: 5px;
}
.right-slider-btn-S{
	right: 5px;
}
.slick-img-box{
	overflow: hidden;
	margin: 0 5px;
	width: 190px!important;
}
