.Activity-wrap {
	padding: 0 13rem !important;
	margin-bottom: 20px !important;
	.titleBar {
		padding: 0 13rem;
	}
	.Activity-title {
		margin-top: 25px !important;
		ul {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 0 !important;
			li {
			}
			li:nth-child(1) {
				font-size: 20px;
				display: flex;
				align-items: center;
				h6 {
					font-size: 20px;
					color: #333333;
					font-weight: 900;
				}
				span {
					color: #999999;
					// font-size: 16px;
					font-size: 14px;
					margin-left: 10px !important;
					margin-bottom: 8px;
				}
			}
			li:nth-child(2) {
				display: flex;
				align-items: center;
				span {
					color: #777777;
				}
				.ant-select-selection-item {
					color: #333333;
					font-weight: bold;
				}
			}
		}
		.m-show {
			display: none;
		}
		.isShow {
			display: block;
		}
		.Activity-select-P {
			position: relative;
			.Activity-select {
				width: 105px !important;
			}
		}
		.Activity-select-M {
			display: none;
		}
	}
	.ant-select-arrow {
		display: none;
	}
	.P-icon-a {
		position: absolute;
		right: 20px;
		top: 10px;
	}
	.Activity-content {
		//display: grid;
		//grid-template-columns: auto auto auto;
		display: flex;
		flex-wrap: wrap;
		dl {
			width: 33.3333333%;
			cursor: pointer;

			margin-top: 25px;
			padding: 0 10px;
			dt {
				img {
					min-height: 200px;
					width: 100%;
					height: 200px;
					max-width: 100%;
					max-height: 100%;
				}
			}
			dd {
				background: #ffffff;
				border: 1px solid #eeeeee;
				padding: 10px !important;
				p:nth-child(1) {
					display: flex;
					justify-content: space-between;
					span:nth-child(1) {
						color: #222222;
						font-size: 16px;
						font-weight: 900;
						width: 37%;
					}
					span {
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						display: block;
						white-space: nowrap;
					}
					span:nth-child(2) {
						flex: 1;
						text-align: right;
						display: flex;
						justify-content: flex-end;
						div {
							em {
								margin: 0 2px;
								font-size: 10px;
							}
							img {
								width: 13px;
								height: 13px;
								margin: 0 2px;
							}
						}
					}
					em {
						font-style: normal;
					}
				}
				b {
					font-weight: normal;
					color: #999999;
					// font-size: 14px;
					font-size: 10px;
				}
			}
		}
		.status {
			div {
				em {
					img {
						width: auto;
						height: auto;
						max-width: 100%;
						max-height: 100%;
					}
				}
			}
		}
	}
	.moreBtn {
		height: 48px;
		background: rgba(245, 245, 245, 1);
		text-align: center;
		color: #999999;
		display: flex;
		flex-direction: column;
		cursor: pointer;
		b {
			padding-top: 8px !important;
		}
	}
}
.greyBg{
	background: rgba(0, 0, 0,.6);
	position: absolute;
	width: calc(100% - 20px);
	height: calc(100% - 8px);
	span{
		color: #fff;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		padding: 8px 20px;
		background: #fc5869;
		border-radius: 5px;
	}
}
.ActivityType-M {
	div {
		padding: 5px 10px;
		border-bottom: 1px solid #cccccc;
	}
	.ant-popover-inner-content {
		padding: 0;
	}
}
@media screen and (max-width: 1300px) {
	.Activity-wrap {
		padding: 0 10px !important;
	}
}
@media screen and (max-width: 750px) {
	.Activity-wrap {
		padding: 0 5px !important;
		margin-top: 40px;
		border-top: 1px solid #eeeeee;
		.Activity-title {
			ul {
				display: flex;
				flex-direction: column;
				li {
					width: 100%;
					white-space: nowrap;
					display: flex;
					align-items: center;
					justify-content: flex-start !important;
				}
				li:nth-child(1) {
					font-size: 14px;
					span {
						font-size: 13px;
					}
				}
				li:last-child {
					> span {
						display: none;
					}
				}
			}
			.Activity-select-P {
				display: none;
			}
			.Activity-select-M {
				display: flex;
				align-items: center;
			}
		}
		.Activity-content {
			display: flex;
			flex-direction: column;
			dl {
				width: 100% !important;
			}
			dl {
				margin-right: 0;
			}
		}
	}
	.Activity-select {
		width: 90px;
	}
	.m-hied {
		display: none !important;
	}
}
