.ContactWrap {
	padding: 0 13rem;
	border: 0;

	.ant-tabs-nav::before {
		border: 0 !important;
	}

	.ant-tabs-nav-list {
		.ant-tabs-tab {
			border-right: 1px solid #cccccc;
			margin: 20px 0 0 0 !important;
			padding: 0 10px !important;
		}

		.ant-tabs-tab {
			a {
				color: rgba(119, 119, 119, 1) !important;
			}
		}

		.ant-tabs-tab:first-child {
			padding-left: 0 !important;
		}

		.ant-tabs-tab:nth-of-type(4) {
			border: 0;
		}

		.ant-tabs-nav .ant-tabs-tab-active {
			a {
				color: #fc5869 !important;
			}
		}

		.ant-tabs-tab:hover {
			a {
				color: #fc5869 !important;
			}
		}

		.ant-tabs-ink-bar {
			display: none !important;
		}
	}

	.ContactWrap-content {
		dl {
			border-top: 2px solid #ddd;
			display: flex;
			align-items: center;
			padding-top: 40px;

			dt {
				flex-grow: 1;
				display: flex;
				text-align: center;
				justify-content: flex-start;
				margin-left: 100px;

				span {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;

					em,
					i {
						font-size: 16px;
						font-style: normal;
					}
				}
			}

			dd {
				p:nth-child(1) {
					padding-left: 8px;
					border-left: 3px solid #fc5869;
				}

				p:nth-child(2) {
					display: flex;
					flex-direction: column;

					span {
						margin-bottom: 6px;
					}
				}

				span {
					i {
						color: #f1a23d !important;
						font-size: 15px;
						margin-right: 6px;
					}

					em {
						font-style: normal;
						color: rgba(119, 119, 119, 1);
					}

					b {
						color: #389afc;
						font-weight: normal;
						//border-bottom: 1px solid #389AFC;
					}
				}
			}
		}

		dl {
			dd {
				margin-right: 36px;
			}
		}
	}
}

@media screen and (max-width: 1300px) {
	.ContactWrap {
		padding: 0 10px !important;
	}
}

@media screen and (max-width: 750px) {
	.ContactWrap {
		padding: 0 5px !important;

		.ContactWrap-content {
			dl {
				display: flow-root;

				dt {
					margin: 0;

					img {
						width: 87px;
					}
				}
			}
		}
	}
}
