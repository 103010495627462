.TabsBos{
	padding: 0 13rem!important;
}
.TabsBos .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn, .TabsBos .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:hover {
    color: #fc5869!important;
    padding: 0;
    font-weight: 700;
}
.TabsBos .ant-tabs-nav-list div:nth-child(2)>div:before {
	content: "|";
    color: #000;
    margin:0 8px;
}
.TabsBos .ant-tabs-tab {
    font-size: 20px;
    color: #777;
	font-weight: 700;
	border: none;
}
.ant-tabs-tab:hover{
	color: #fc5869!important;
}
.ant-tabs-ink-bar{
	background-color: transparent !important;
  }

// 电影图片内容样式
	.dy-img-box{
		.img-box{
			// width: 210px;
			// height: 270px;
			background-image: url(../../assets/images/dy-mr-bg.jpeg);
			background-repeat: no-repeat;
			background-size: cover;
			position: relative;
			margin: 0 5px;
			overflow: hidden;
			img{
				width: 100%;
			}
			.DY-img-show{
				height: calc(100vw * 0.195);
			}
			.DY-text-show{
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				width: 100%;
				// height: 270px;
				overflow: hidden;
				opacity: 0;
				p{
					width: 100%;
					height: 90px;
					padding: 20px 20px 0;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 4;
					-webkit-box-orient: vertical;
					color: #fff;
					text-align: center;
				}
				.DY-texts{
					width: 100%;
					height: 48px;
					line-height: 48px;
					color: #feae04;
					font-size: 18px;
					border-top: 1px solid #ccc;
					text-align: center;
				}
				b{
					width: 100px;
					display: block;
					background: transparent;
					padding: 2px 5px!important;
					margin: 0 auto 20px;
					color: #fff;
					border: 1px solid #fff;
					border-radius: 100px;
					cursor: pointer;
					text-align: center;
				}
			}
		}
		.img-box:hover {
			.DY-img-show {
				animation: Rota 1s linear;
				transform-style: preserve-3d;
				transition: linear 1s;
			}
			.DY-text-show {
				opacity: 1;
			}
		}
		.bottom-box{
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
		}
		.dy-name{
			font-weight: 900;
			font-size: 18px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			text-align: center;
			line-height: 40px;
		}
	}
	.slider-btn{
		background: #fc5869;
		position: absolute;
		top:30%;
		// transform: translateY(-50%);
		color: #fff;
		width: 30px;
		line-height: 70px;
		text-align: center;
	}
	.left-slider-btn{
		left: 5px;
	}
	.right-slider-btn{
		right: 5px;
	}

@media screen and (max-width: 1400px) {

		.DY-img-show {
			// height: 265px !important;
			height: calc(100vw * 0.25) !important;
			// img {
			// 	height: 100% !important;
			// 	max-width: 100%;
			// 	max-height: 100%;
			// }
		}
	
	.TabsBos {
		padding: 0 30px !important;
		
	}
}

@media screen and (max-width: 750px) {
	.slider-btn{
		display: none;
	}
	.slick-slider{
		padding: 0 5px;
	}
	.img-box{
		height: 150px!important;
		margin: 0 2px!important;
		img{

			height: 150px!important;
		}
		.DY-text-show{
			height: 150px!important;
		}
	}
	.TabsBos {
		padding: 0 5px !important;

		.DY-content-P {
			display: none;
		}
		.dy-img-box{
			margin: 0 5px;
		}
		.DY-content-L {
			dl {
				width: 100% !important;
			}
		}

		.DY-content-M {
			display: block;

			.DY-img-show {
				height: 150px !important;
			}
		}

		.DY-content {
			//pointer-events: none;
			dt {
				height: auto !important;
			}
		}

		.DY-text-show {
			opacity: 0 !important;
			display: none !important;
		}

		.ant-tabs-tab {
			font-size: 15px !important;
			padding-left: 10px !important;
		}

		.moreBtn {
			font-size: 13px !important;
		}

		.swiper-button,
		.swiper-button-next,
		.swiper-button-prev {
			display: none;
		}

		.DY-content-M {
			dl {
				dd {
					font-size: 15px;

					div {
						font-size: 15px !important;
					}

					div:nth-child(2) {
						font-size: 13px !important;
						color: #777777 !important;
					}
				}
			}
		}
	}
}