.FriendWrap {
	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:hover {
		color: #fc5869 !important;
		// padding: 0 8px;
		font-weight: bold;
	}
	.ant-tabs-tab {
		font-size: 20px;
		color: #777777;
		font-weight: bold;
	}
	.ant-tabs-ink-bar {
		display: none !important;
	}
	.ant-tabs-tab:hover {
		color: #fc5869 !important;
	}
	.ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list div:nth-child(2) > div::before {
		content: '|';
		color: #000000;
		margin: 0 8px;
	}

	.ant-tabs-bar {
		border: 0;
		.ant-tabs-tab {
			border-right: 1px solid #cccccc;
			margin: 20px 0 0 0 !important;
			padding: 0 10px !important;
		}
		.ant-tabs-tab:last-child {
			border: 0;
		}
		.ant-tabs-nav .ant-tabs-tab-active {
			color: #fc5869;
			// padding:0 8px;
		}
		.ant-tabs-tab:hover {
			color: #fc5869;
		}
		.ant-tabs-ink-bar {
			display: none !important;
		}
	}
	.FriendWrap-tabs-content-bg {
		background: rgb(245, 245, 245);
	}
	.PACONNIE-tabs-content {
		text-align: center;
		margin-bottom: 20px !important;
		img {
			width: 640px;
			margin-bottom: 20px !important;
		}
	}
	.FriendWrap-content {
		padding: 0 13rem;
		.FriendWrap-tabs {
			.FriendWrap-tabs-content {
				padding: 20px;
				background: url("../../assets/images/friend-bg.png")no-repeat;
				background-position: 50% 50%;
				display: flex;
				ul {
					display: flex;
					flex-direction: column;
					justify-content: center;
					li:nth-child(1) {
						margin: 0;
					}
					li {
						margin-top: 20px;
						p {
							// font-size: 20px;
							font-size: 14px;
							margin-bottom: 0;
						}
						span {
							color: rgba(119, 119, 119, 1);
						}
					}
				}
			}
		}
		.privilege {
			h6 {
				// font-size: 20px;
				font-size: 18px;
			}
			.ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list div:nth-child(2) > div::before {
				content: none;
			}
			.privilege-content {
				.ant-tabs-bar {
					border-bottom: 1px solid #eeeeee;
				}
				.ant-tabs-nav .ant-tabs-tab {
					margin: 0;
				}
				.ant-tabs-tab {
					border: 0;
					height: 35px;
				}
				.ant-tabs-nav {
					width: 100%;
					> div {
						display: grid;
						grid-template-columns: auto auto auto auto;
						text-align: center;
					}
				}

				.ant-tabs-nav .ant-tabs-tab-active {
					color: #fc5869;
					border-bottom: 3px solid #fc5869;
				}
				.buttonBorder {
					border-bottom: 3px solid #fc5869;
				}
				.privilege-tabs-content {
					width: 100%;
					display: flex;
					flex-wrap: wrap;
					dl {
						width: 25%;
						text-align: center;
						display: flex;
						flex-wrap: wrap;
						flex-direction: column;
						dt {
							img {
								width: 48px;
								height: 48px;
								// width: 40px;
								// height: 40px;
							}
						}
						dd {
							p {
								margin-bottom: 0;
								font-weight: 600;
								// font-size: 18px;
								font-size: 16px;
								padding-top: 12px;
								padding-bottom: 6px;
							}
							span {
								color: #999999;
								// font-size: 16px;
								font-size: 14px;
							}
						}
					}
				}
				.privilege-pic1 {
					font-size: 12px;
					i {
						padding: 1px 10px;
						background: url("../../assets/images/privilege-icon1.png")no-repeat;
						background-size: 90%;
					}
				}
				.privilege-pic2 {
					font-size: 12px;
					i {
						padding: 1px 10px;
						background: url("../../assets/images/privilege-icon2.png")no-repeat;
						background-size: 100%;
					}
				}
				.privilege-pic3 {
					font-size: 12px;
					i {
						padding: 1px 10px;
						background: url("../../assets/images/privilege-icon3.png")no-repeat;
						background-size: 100%;
					}
				}
				.privilege-pic4 {
					font-size: 12px;
					i {
						padding: 1px 10px;
						background: url("../../assets/images/privilege-icon4.png")no-repeat;
						background-size: 100%;
					}
				}
			}
		}
	}
}
@media screen and (max-width: 1300px) {
	.FriendWrap-content {
		padding: 0 10px !important;
		.PACONNIE-tabs-content {
			img {
				width: 640px;
			}
		}
	}
}
@media screen and (max-width: 750px) {
	.FriendWrap-content {
		padding: 0 5px !important;
		.FriendWrap-tabs-content {
			background: #f5f5f5 !important;
		}
		ul {
			display: flex;
			flex-direction: column;
			li {
				margin: 10px 0;
				p {
					font-size: 15px;
				}
				span {
					font-size: 13px;
				}
			}
		}
		.privilege {
			h6 {
				margin-top: 20px;
			}
			.ant-tabs-bar,
			.ant-tabs-content {
				padding: 0 5px !important;
			}
		}

		.PACONNIE-tabs-content {
			img {
				width: 100%;
			}
		}
	}
	.SpecialEffects-Content {
		.Special-content-tabs {
			.ant-tabs-nav-container-scrolling {
				padding: 0 !important;
			}
			.ant-tabs-tab-prev,
			.ant-tabs-tab-next {
				display: none !important;
			}
		}
	}
}
.FriendWrap .FriendWrap-content .privilege .privilege-content .privilege-tabs-content {
	min-height: 260px;
}
