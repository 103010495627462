.FilmWrap {
	padding: 0 13rem !important;
	margin-bottom: 2rem !important;

	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:hover {
		color: #fc5869 !important;
		// padding: 8px;
		font-weight: bold;
	}
	.ant-tabs-nav-list div:nth-child(2) > div::before {
		content: '|';
		color: #000000;
		margin: 0 8px;
	}
	.ant-tabs-tab:hover {
		color: #fc5869 !important;
	}
	.ant-tabs-ink-bar {
		display: none !important;
	}
	.ant-tabs-tab {
		font-size: 20px;
		color: #777777;
		font-weight: bold;
	}
	.ant-tabs-bar {
		border: 0;
		margin-left: 10px;

		.ant-tabs-tab {
			border-right: 1px solid #cccccc;
			margin: 20px 0 0 0 !important;
			padding: 0 10px 0 0;
			font-size: 16px;
		}

		.ant-tabs-tab:last-child {
			border: 0;
			padding-left: 10px;
		}

		.ant-tabs-nav .ant-tabs-tab-active {
			color: #fc5869;
		}

		.ant-tabs-tab:hover {
			color: #fc5869;
		}

		// .ant-tabs-ink-bar {
		//   display: none !important;
		// }
	}
	.FilmWrap-Content-left {
		dl {
			dd {
				color: #300300;
				font-size: 18px;
				font-weight: bold;
			}
		}
		.DY-img-show {
			height: 270px;
			img {
				//width: 100px;
				//height: auto;
				max-width: 100%;
				max-height: 100%;
			}
		}
	}

	.FilmWrap-Content {
		text-align: center;
		.FilmWrap-P,
		.FilmWrap-M {
			dl {
				height: 90%;
				dt {
					height: 270px !important;
					background: url("../../assets/images/dy-mr-bg.jpeg") no-repeat;
					background-size: cover;
				}
				dd {
					font-weight: bold;
				}
			}
		}
	}

	.FilmWrap-Content {
		// height: 90%;
		.FilmWrap-P {
			dl:hover {
				dt {
					.DY-img-show {
						animation: Rota 1s linear;
						transition: linear 1s;
						transform-style: preserve-3d;
					}

					.DY-text-show {
						opacity: 1;
					}
				}
			}
		}
	}

	@keyframes Rota {
		0% {
			transform: rotateY(0deg);
		}
		50% {
			transform: rotateY(90deg);
		}
		100% {
			transform: rotateY(0deg);
		}
	}

	.FilmWrap-Content {
		//display: grid;
		//grid-template-columns: auto auto auto auto auto ;
		display: flex;
		flex-wrap: wrap;

		.FilmWrap-M {
			display: none;
		}

		> div {
			width: 20%;
		}

		dl {
			dt {
				height: 270px !important;

				.DY-img-show {
					height: 270px !important;

					img {
						width: 100% !important;
						height: 100% !important;
					}
				}
			}
		}

		.FilmWrap-P {
			dl {
				text-align: center;
				margin-right: 10px;

				dt {
					position: relative;
					height: 270px !important;

					img {
						width: 100%;
						height: 100% !important;
						max-width: 100%;
						max-height: 100%;
					}

					.DY-text-show {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background: rgba(0, 0, 0, .5);
						color: #ffffff;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						padding-top: 20px !important;
						opacity: 0;
						> div {
							width: 100%;
							display: flex;
							justify-content: center;
							text-align: center;
						}
						p {
							width: 100%;
							height: 90px;
							padding: 0 20px;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 4;
							-webkit-box-orient: vertical;
						}

						button {
							width: 100px;
							background: transparent;
							color: #ffffff;
							border: 1px solid #cccccc;
							border-radius: 100px;
							position: absolute;
							bottom: 60px;
							left: 50%;
							transform: translateX(-50%);
						}

						div:nth-child(1) {
							height: 80%;
						}

						div:nth-child(2) {
							color: #feae04;
							width: 100%;
							text-align: center;
							border-top: 1px solid #cccccc;
							span {
								display: block;
								height: 25px;
								//line-height: 30px;
							}
						}
					}
				}

				dd {
					cursor: pointer;
				}
			}
		}
	}

	.FilmWrap-Content-left {
		dl {
			dd {
				margin-top: 11px;
				font-size: 18px;
				font-weight: 500;
			}
		}
	}

	.FilmWrap-Content-right {
		dl {
			dd {
				cursor: pointer;

				div:first-child {
					color: #300300;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					font-weight: 900;
					margin-top: 11px;
					font-size: 18px;
				}

				div:nth-child(2) {
					color: rgba(119, 119, 119, 1);
					font-size: 14px;
				}
			}
		}
	}

	.moreBtn {
		height: 48px;
		background: rgba(245, 245, 245, 1);
		text-align: center;
		color: rgba(153, 153, 153, 1) !important;
		display: flex;
		flex-direction: column;
		// margin-top:30px;
		cursor: pointer;

		b {
			padding-top: 8px !important;
			font-weight: normal;
		}

		i {
			display: flex;
			justify-content: center;
		}
	}
}

@media screen and (max-width: 1300px) {
	.FilmWrap {
		padding: 0 10px !important;
		.DY-img-show {
			height: 270px !important;
		}
	}
}

@media screen and (max-width: 750px) {
	.FilmWrap {
		padding: 0 5px !important;

		.FilmWrap-Content {
			//display: grid;
			//grid-template-columns: auto auto;
			flex-wrap: wrap;
			> div {
				width: 33%;
			}

			.FilmWrap-P {
				display: none;
			}

			.FilmWrap-M {
				display: flex;
				justify-content: center;

				dl {
					width: 100%;

					dt {
						margin: 10px;
						height: 150px !important;
						.DY-img-show {
							height: 150px !important;
						}
						img {
							width: 100%;
							height: 100%;
						}

						.DY-text-show {
							display: none;
						}
					}

					dd {
						margin-left: 10px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
			}

			.DY-text-show {
				opacity: 0 !important;
			}
		}
	}
}
