.DevelopmentWrap .ant-tabs-nav-list .ant-tabs-tab-active a {
	color: #fc5869 !important;
}

.DevelopmentWrap {
	border: 0;
	padding: 0 13rem;
	color: #777777;
	p {
		font-size: 16px;
	}

	.ant-tabs-nav::before {
		border: 0 !important;
	}

	.ant-tabs-nav-list {
		.ant-tabs-tab {
			border-right: 1px solid #cccccc;
			margin: 20px 0 0 0 !important;
			padding: 0 10px !important;
		}

		.ant-tabs-tab {
			a {
				color: rgba(119, 119, 119, 1) !important;
			}
		}

		.ant-tabs-tab:first-child {
			padding-left: 0 !important;
		}

		.ant-tabs-tab:nth-of-type(4) {
			border: 0;
		}

		.ant-tabs-nav .ant-tabs-tab-active {
			a {
				color: #fc5869 !important;
			}
		}

		.ant-tabs-tab:hover {
			a {
				color: #fc5869 !important;
			}
		}

		.ant-tabs-ink-bar {
			display: none !important;
		}
	}

	.DevelopmentWrap-title {
		p {
			color: rgba(119, 119, 119, 1) !important;
		}
	}

	.DevelopmentWrap-content {
		dl {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-content: center;

			dd {
				margin: 10px 0;
				text-align: center;
				background: rgba(245, 245, 245, 1);
				color: rgba(51, 51, 51, 1);
				padding: 24px;
			}

			dt {
				display: flex;
				padding: 0 30px;

				img {
					width: 100%;
				}

				span {
					margin: 10px !important;
				}
			}
		}

		dl:nth-child(2) {
			dd {
				display: flex;
				flex-direction: column;
			}

			dt {
				display: flex;

				span {
					width: 100% !important;

					em {
						i {
							display: block;
							font-style: normal;
							text-align: center;
						}
					}
				}
			}
		}

		dl:nth-child(3) {
			dt {
				span {
					padding: 0 30px;
				}
			}
		}

		.show-P {
			dl:first-child {
				dt {
					span {
						em {
							font-style: normal;

							i {
								display: block;
								text-align: center;
								font-style: normal;
								margin: 10px 0;
							}
						}
					}
				}
			}
		}
	}

	.show-M {
		display: none;

		dl {
			dt {
				ol {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					padding-left: 20px;

					li {
						width: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
						margin: 10px 0;

						div:nth-child(1) {
							width: 133px;

							img {
								width: 100%;
							}
						}

						div:nth-child(2) {
							flex-grow: 1;
							display: flex;
							text-align: left;
							align-items: center;
							padding-left: 20px;
						}
					}

					li:nth-child(1) {
						div {
							img {
								width: 54px;
							}
						}
					}

					li:nth-child(2) {
						div {
							img {
								width: 100px;
							}
						}
					}

					li:last-child {
						div {
							img {
								width: 90px;
							}
						}
					}
				}
			}

			.pic-text {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				font-size: 11px;
				padding: 20px 0 0 0;
			}
		}

		dl:nth-child(2) {
			dd {
				margin: 0 5px;

				p {
					margin-bottom: 0;
				}
			}

			dt {
				margin: 10px;

				img {
					margin: 0 10px;
					width: 100%;
				}
			}
		}
	}
}

@media screen and (max-width: 1300px) {
	.DevelopmentWrap {
		padding: 0 10px;
	}
}

@media screen and (max-width: 750px) {
	.DevelopmentWrap {
		padding: 0 5px;

		.DevelopmentWrap-content {
			dl {
				dt {
					display: flex;
					flex-direction: column;
					padding: 0;

					span {
						margin: 10px 0 !important;
					}
				}
			}

			dl:nth-child(2) {
				dt {
					span {
						em {
							margin: 10px 0;

							i {
								margin: 10px 0;
							}
						}
					}
				}
			}

			.show-P {
				display: none;
			}

			.show-M {
				display: block;
			}
		}
	}
}
