.McityWrap{
  margin-top: 50px;
  ol{
    li{
      p{
        background: #F3F4F5;
        padding: 5px 15px;
      }
      span{
        padding: 5px 0 5px 15px;
        display: block;
        div{
          span{
            border-bottom: 1px solid #cccccc;
          }
        }
      }
    }
  }
  .McityWrap-tip{
    position: fixed;
    right: 10px;
    font-size: 10px;
    display: flex;
    flex-direction: column;
  }
  .right-title{
    .ant-radio-wrapper-checked{
      span{
        color: red!important;
      }
    }
  }
}
