
.Notice {
  display: flex;
  height: 48px;
  background: rgba(245, 245, 245, 1);
  padding: 0 13rem 0 13rem !important;
  overflow: hidden;

  p {
    width: 55px;
    min-width: 55px;
    margin-right: 10px !important;
    margin-top: 15px !important;
    margin-bottom: 0;
    background: url("../../assets/images/m-gongao2.png") no-repeat;
    background-size: 100%;
  }
  >span{
    padding: 0 10px;
    display: inline-block;
    line-height: 45px;
  }
  .Notice-Title {
    width: 100%;
    padding-top: 12px !important;

    button {
      display: none !important;
    }
  }

  .Notice-content {
    width: 100%;
    //color:rgba(119,119,119,1);
    padding-top:10px;
    display: flex!important;
    justify-content: space-between;
    li:first-child {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      // color: rgba(119, 119, 119, 1);
      color: #777777;
      width: 50%;
      font-size: 16px;
    }

    li:last-child {
      color: rgb(150, 140, 140);
      font-size: 16px;
    }
    li:nth-child(2){
      font-size: 16px;
    }
    li:nth-child(2){
      span:nth-child(2):hover{
        font-size: 16px;
        color: #fc5869!important;
      }
    }
    li{
      span:last-child{
        cursor: pointer;
      }
    }

    .slick-slider .slick-vertical .slick-initialized {
      padding-top: 12px;
    }
    .slick-dots .slick-dots-left{
      display: none!important;
    }
  }
}
@media screen and (max-width: 1300px) {
  .Notice {
    padding: 0 30px !important;
    font-size: 16px;
    p {
      width: 55px;
      min-width: 55px;
      margin-right: 10px !important;
      margin-top: 15px !important;
      margin-bottom: 0;
      background: url("../../assets/images/m-gongao2.png") no-repeat;
      background-size:100%;
    }
    .Notice-content {
      justify-content: space-between;
      li{
        padding-top: 5px;
        font-size: 13px!important;
      }
      li:nth-child(1) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 60%;
    }
    li:nth-child(2){
      span:nth-child(2){
        margin-right: 0!important;
      }
    }
    .m-hide {
      display: none;
    }
  }
  }
}

@media screen and (max-width: 750px) {
  .Notice {
    padding: 0 15px !important;
    p {
      width: 55px;
      min-width: 55px;
      margin-right: 0px !important;
      margin-top: 19px !important;
      margin-bottom: 0;
      background: url("../../assets/images/m-gongao2.png") no-repeat;
      background-size:80%;
    }
    .Notice-content {
        justify-content: space-between;
        li{
          padding-top: 5px;
          font-size: 13px!important;
        }
        li:nth-child(1) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 60%;
      }
      li:nth-child(2){
        span:nth-child(2){
          margin-right: 0!important;
        }
      }
      .m-hide {
        display: none;
      }
    }

  }
}
