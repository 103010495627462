.footer > div {
	padding: 0 13rem;
}
.footer {
	background: rgba(245, 245, 245, 1);
	padding: 20px 0 20px !important;
	text-align: center;
	a {
		color: #333333;
	}

	a:hover {
		color: #fc5869;
	}

	.footer-logo {
		// padding: 20px 0 26px 0;

		.footer-logo-content {
			display: block;
			width: 90px;
			height: 30px;
			background: url("../../assets/images/logo1.png") no-repeat;
			background-size: 100%;
		}
	}

	.footer-list {
		ul {
			display: flex;
			flex-wrap: wrap;
			font-size: 14px;
			li {
				a {
					color: rgba(119, 119, 119, 1) !important;
					margin-right: 15px;
					margin-left: 15px;
				}
				i {
					display: none;
					font-style: normal;
				}
			}
			li:nth-child(1) {
				a {
					margin-left: 0;
				}
			}
			li {
				a:hover {
					color: #fc5869 !important;
					display: inline-block;
				}
			}
		}
	}

	.footer-Authentication {
		ul {
			display: flex;
			align-items: center;
			font-size: 10px;
			margin: 0;
			padding: 0;
			li {
				word-break: keep-all;
				color: rgb(153, 153, 153) !important;
				em {
					font-style: normal;
					margin-right: 10px !important;
				}

				dl {
					display: flex;
					align-items: center;
					margin-bottom: 0 !important;
					cursor: pointer;

					dt {
						width: 20px;
						height: 20px;
						background: url("../../assets/images/shgs.png") no-repeat;
						background-size: 100%;
					}

					dd {
						display: flex;
						align-items: center;
						flex-direction: column;
						font-size: 9px;
						-webkit-transform-origin-x: 0;
						-webkit-transform: scale(0.70);
						text-align: left;
						margin-left: 2px !important;

						span {
							width: 100%;
							display: block;
						}
					}
				}

				dl:nth-child(2) {
					dt {
						width: 20px;
						height: 20px;
						background: url("../../assets/images/jc.png") no-repeat;
						background-size: 100%;
					}
				}
			}

			li:nth-child(2) {
				display: flex;
				justify-content: center;
			}
		}
	}
	.footer-Authentications {
		display: none;
	}
	.footer-Authentication {
		background-color: #f5f5f5;
	}
	.footer-ERcode {
		margin-top: 30px;
		div {
			display: flex;
			justify-content: flex-end;
			margin-top: -140px !important;
			color: rgba(119, 119, 119, 1) !important;
			// padding: 0 13rem;
		}

		dl {
			margin: 0 10px !important;

			dt {
				width: 85px;
				height: 85px;
				background: url("../../assets/images/kud.png") no-repeat #f5f5f5;
			}

			dd {
				text-align: center;
				font-size: 12px;
			}
		}

		dl:nth-child(2) {
			width: 85px;

			dt {
				width: 85px;
				height: 85px;
				background: url("../../assets/images/gzh2.png") no-repeat;
				background-size: 100%;
			}

			dd {
			}
		}
	}
}
@media screen and (max-width: 1300px) {
	.footer > div {
		padding: 0 13rem;
	}
	.footer {
		padding: 0 30px !important;
		.footer-ERcode {
			div {
				margin: 0 !important;
				justify-content: flex-start;
			}
		}
	}
}
@media screen and (max-width: 1200px) {
	.footer > div {
		padding: 0 8rem !important;
	}
	.footer {
		padding: 0 !important;
		.content-left {
			div {
				p {
					text-align: left;
				}
			}
		}
		.FilmWrap-M {
			di {
				text-align: center;
			}
		}
		.footer-logo {
			display: flex;
			justify-content: center;
			padding-top: 15px !important;
			padding-bottom: 10px !important;
		}
		.footer-ERcode {
			color: red !important;
			order: 1;
		}
		.footer-Authentication {
			width: 100%;
			display: flex;
			justify-content: center;
			order: 2;
			ul {
				display: flex;
				flex-direction: column;
				li:nth-child(1) {
					margin: 0 !important;
				}
			}
		}
		.m-hide {
			display: none !important;
		}
		.footer-list {
			padding: 0 15px;
			ul {
				flex-wrap: wrap;
				margin-left: 18%;
				li {
					white-space: nowrap;
				}
			}
		}
		.footer-ERcode {
			width: 100%;
			justify-content: center;
			div {
				margin-left: 35% !important;
			}
		}
	}
}
@media screen and (max-width: 750px) {
	.footer > div {
		padding: 0 !important;
	}
	.footer {
		padding: 0 !important;
		.content-left {
			div {
				p {
					text-align: left;
				}
			}
		}
		.FilmWrap-M {
			di {
				text-align: center;
			}
		}
		.footer-logo {
			display: flex;
			justify-content: center;
			padding-top: 10px !important;
			padding-bottom: 5px !important;
		}
		.footer-ERcode {
			color: red !important;
			order: 1;
		}
		.footer-Authentication {
			width: 100%;
			display: flex;
			justify-content: center;
			order: 2;
			ul {
				display: flex;
				flex-direction: column;
				li:nth-child(1) {
					margin: 0 !important;
				}
			}
		}
		.m-hide {
			display: none !important;
		}
		.footer-list {
			padding: 0 15px;
			ul {
				flex-wrap: wrap;
				margin-left: 0;
				li {
					white-space: nowrap;
				}
			}
		}
		.footer-ERcode {
			width: 100%;
			justify-content: center;
			div {
				margin-top: 0 !important;
				margin-left: 0 !important;
			}
		}
	}
}
