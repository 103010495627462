.HelpWrap {
	.HelpWrap-content {
		padding: 0 13rem;
		margin-bottom: 20px;
		p {
			font-size: 20px;
			padding: 20px 0;
			margin-bottom: 0;
			font-weight: bold;
		}
		.HelpWrap-list {
			border-top: 2px solid #eee;
			ol {
				li {
					display: flex;
					justify-content: space-between;
					border-bottom: 2px solid #eee;
					padding: 30px 0;
					cursor: pointer;
					font-size: 16px;
					// font-family:默认;
					i {
						font-style: normal;
						color: #333333;
						font-weight: bold;
						padding-top: 5px;
					}
					div:nth-child(1) {
						width: 60%;
						display: flex;
						i {
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
						em {
							background: #fc5869;
							color: white;
							font-style: normal;
							border-radius: 18px;
							padding: 2px 12px;
							margin-right: 12px;
						}
					}
					div:nth-child(2) {
						width: 15%;
						text-align: right;
						font-size: 16px;
						color: #999999;
					}
					// span:nth-child(1){

					// }
					span:nth-child(2) {
						color: #999999;
					}
				}
			}
		}
		.loadingMore {
			background: #f5f5f5;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 8px 0;
			flex-direction: column;
			color: #999999;
		}
	}
	.HelpWrapDetails-title {
		ul {
			li {
				span {
					font-weight: normal !important;
					color: red;
				}
			}
			li:nth-child(1) {
				color: red;
				span:nth-child(1) {
					em {
						img {
							width: auto;
							height: auto;
							max-width: 100%;
							max-height: 100%;
							margin-right: 10px;
						}
					}
				}
				span:nth-child(2) {
					color: #333333 !important;
					font-size: 20px;
				}
			}
			li:nth-child(2) {
				span {
					color: #777777;
					font-size: 16px;
				}
			}
		}
		.G-text {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
	.HelpWrapDetails-content {
		color: #777777;
		font-size: 16px;
		img {
			width: auto;
			height: auto;
			max-width: 100%;
			max-height: 100%;
		}
	}
}

@media screen and (max-width: 1300px) {
	.HelpWrap {
		.HelpWrap-content {
			padding: 0 10px;
		}
	}
}
@media screen and (max-width: 750px) {
	.HelpWrap {
		.HelpWrap-content {
			padding: 0 5px;
			p {
				font-size: 15px;
			}
			.HelpWrap-list {
				ol {
					li {
						display: flex;
						// flex-direction: column;
						padding: 15px 0;
						span {
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							em {
								font-size: 11px;
							}
							font-size: 15px;
						}
						span:nth-child(2) {
							text-align: right;
						}
					}
					li {
						div:nth-child(1) {
							width: 100% !important;
						}
						div:nth-child(2) {
							text-align: right;
							color: #999999;
							width: 100%;
						}
					}
				}
			}
			.HelpWrapDetails-title {
				ul {
					display: flex !important;
					flex-direction: column;
				}
			}
		}
	}
}
