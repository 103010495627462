.MoviesBox{
  background: url("../../assets/images/ys-bg.png")no-repeat;
  background-size: 100% 100%;
  color: white;
  padding: 30px 13rem;
  .MoviesBox-P{
    padding: 30px 0;
    img{
      width: 100%;
    }
  }
  .MoviesBox-M{
    display: none;
    img{
      width: 100%;
    }
  }
  .MoviesTop{
    ul{
      display: flex;
      flex-direction: column;
      text-align: center;
      li:nth-child(1){
        color:rgba(255,255,255,1);
      }
      li:nth-child(2){
        padding-top: 20px;
      }
      li:nth-child(3){
        letter-spacing: 6px;
        color: #F1A23D;
      }
      li:nth-child(4){
        display: flex;
        padding-top: 25px;
        justify-content: center;
        align-items: center;
        color: #F1A23D;
        em:nth-child(1){
          display: block;
          width: 100%;
          max-width: 20%;
          height: 2px;
          background-image: linear-gradient(#F1A23D, #F1A23D);
          border-top-left-radius: 100%;
          border-bottom-left-radius: 100%;
          position: relative;
          margin-right: 10px;
          i{
            display: block;
            width: 8px;
            height: 8px;
            background: #F1A23D;
            border-radius: 100%;
            position: absolute;
            right: 0;
            top: -4px;
          }
        }
        em:nth-child(3){
          display: block;
          width: 100%;
          max-width: 20%;
          height: 2px;
          background-image: linear-gradient(#F1A23D, #F1A23D);
          border-top-right-radius: 100%;
          border-bottom-right-radius: 100%;
          position: relative;
          margin-left: 10px;
          i{
            display: block;
            width: 8px;
            height: 8px;
            background: #F1A23D;
            border-radius: 100%;
            position: absolute;
            left: 0;
            top: -4px;
          }
        }
        .rest{
          white-space:nowrap;
        }
      }
    }
  }
  .MoviesBottom-p{
    display: block;
    ol{
      display: grid;
      grid-template-columns: auto auto auto auto;
      li{
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
    ul{
      display: grid;
      grid-template-columns: auto auto auto auto auto;
      li{
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
  .MoviesBottom-m{
    display: none;
    ol{
      display: grid;
      grid-template-columns: auto auto auto auto auto;
      li{
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .MoviesBox{
    padding: 20px 5px;
    .MoviesBox-P{
      display: none;
    }
    .MoviesBox-M{
      display: block;
    }
    .MoviesTop{
      ul{
        li:nth-child(4){
          em{
            width: 100%;
          }
        }
      }
    }
    .MoviesBottom-p{
      display: none;
    }
    .MoviesBottom-m{
      display: block;
      ol{
        height: 100px;
        grid-template-columns: auto auto auto;
      }
    }
  }
}
