.RecruitmentWrap {
  * {
    margin: 0;
    padding: 0;
  }

  padding: 0 13rem;
  margin-bottom: 30px;
  border: 0;

  .ant-tabs-nav::before {
    border: 0 !important;
  }

  .ant-tabs-nav-list {
    .ant-tabs-tab {
      border-right: 1px solid #cccccc;
      margin: 20px 0 0 0 !important;
      padding: 0 10px !important;
    }

    .ant-tabs-tab {
      a {
        color: rgba(119, 119, 119, 1) !important;
      }
    }

    .ant-tabs-tab:first-child {
      padding-left: 0 !important;
    }

    .ant-tabs-tab:nth-of-type(4) {
      border: 0
    }


    .ant-tabs-nav .ant-tabs-tab-active {
      a {
        color: #FC5869 !important;
      }
    }

    .ant-tabs-tab:hover {
      a {
        color: #FC5869 !important;
      }
    }

    .ant-tabs-ink-bar {
      display: none !important;
    }
  }

  .Recruitment-top {
    padding: 50px 0 50px 64px;
    background: url("../../assets/images/Recruitment-bg.png")no-repeat;
    background-size: 100%;
    margin-bottom: 20px;
    margin-top: 40px;

    p {
      font-size: 50px;
      color: white;
      border-bottom: 0;
    }

    span {
      color: #ffffff;
      opacity: 0.8;
      font-size: 30px;
    }
  }

  .Recruitment-list {
    ul {
      li {
        border-top: 2px solid #EEEEEE;
        padding: 28px 0;
        display: flex;
        justify-content: space-between;

        span:nth-child(1) {
          color: #333333;
          font-size: 20px;

          em {
            color: white;
            background: #F1A23D;
            border-radius: 18px;
            display: inline-block;
            font-style: normal;
            padding: 5px 19px;
            font-size: 16px;
            margin-right: 20px;
          }
        }

        span:nth-child(2) {
          font-size: 20px;
          color: #999999;
        }
      }

      li:last-child {
        border-bottom: 2px solid #EEEEEE;
      }
    }
  }

  .RecruitmentWrap-M-Title {
    em {
      margin-right: 20px;
    }
  }
}

@media screen and (max-width: 1300px) {
  .RecruitmentWrap {
    padding: 0 10px;
  }
}

@media screen and (max-width: 750px) {
  .RecruitmentWrap {
    padding: 0 5px;

    .Recruitment-top {
      background: url("../../assets/images/m-Recruitment-bg.png")no-repeat;
      padding: 20px 15px;
      background-size: 100% 100%;

      p {
        font-size: 20px;
      }

      span {
        font-size: 13px;
      }
    }

    .Recruitment-list {
      ul {
        display: flex;
        flex-direction: column;
        margin: 0;

        li:first-child {
          padding-top: 25px !important;
        }

        li {
          display: flow-root;

          span:nth-child(1) {
            color: #333333;
            font-size: 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            em {
              color: white;
              background: #F1A23D;
              border-radius: 18px;
              display: inline-block;
              font-style: normal;
              padding: 2px 9px;
              font-size: 13px;
              margin-right: 0;
            }
          }

          span:nth-child(2) {
            font-size: 15px;
            color: #999999;
            display: block;
            text-align: right;
          }
        }

        li:last-child {
          border-bottom: 2px solid #EEEEEE;
        }
      }
    }
  }
}