@import "assets/font/font";
.swiper-button-prev {
	left: 0 !important;
}
.ContentMax {
	flex-grow: 1;
	margin-top: 60px !important;
}
.ToUp {
	position: fixed;
	right: 200px;
	bottom: 170px;
	width: 44px;
	height: 44px;
	z-index: 9999;
	> div {
		padding: 5px 25px;
	}
	div {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: #fc5869;
		color: #ffffff;
		z-index: 99999999999;
	}
	em {
		width: 16px;
		height: 16px;
		display: block;
		background: url("./assets/images/top.png") no-repeat;
	}
}

.titleBar {
	min-height: 40px;
	background: rgba(245, 245, 245, 1);
	color: red;
	display: flex;
	align-items: center;
	.Home-Icon {
		width: 20px;
		height: 20px;
		max-width: 100%;
		max-height: 100%;
	}
	.site-page-header-ghost-wrapper {
		width: 100%;
		display: flex;
		align-items: center;
		.ant-page-header {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: rgba(245, 245, 245, 1);
			padding: 0 !important;
			.ant-breadcrumb {
				width: 100%;
			}
		}
	}
	.ant-page-header-heading-extra {
		padding: 0 !important;
		display: flex;
		justify-content: flex-end;
		div {
			text-align: right;
			padding-top: -10px !important;
			width: fit-content;
		}
	}
	.ant-breadcrumb .ant-page-header-heading {
		margin: 0 !important;
	}
}
.City-top {
	display: flex;
	ul {
		padding: 0 !important;
		display: flex;
		li {
			margin: 0 10px !important;
			color: #fc5869;
			cursor: pointer;
		}
	}
}
.ant-popover-content {
	box-shadow: 0 0 0 #cccccc;
	border: 1px solid #cccccc;
}
.City-content {
	max-width: 500px;
	max-height: 400px;
	overflow-y: scroll;
	> div {
		overflow-y: hidden !important;
	}
}
.City-content-name {
	display: flex;
	flex-wrap: wrap;
	p {
		cursor: pointer;
		background: #cccccc;
		width: 22px !important;
		height: 22px !important;
		text-align: center;
		vertical-align: middle;
		white-space: nowrap;
	}
	.City-content-names {
		display: inline-block;
		margin: 0 3px;
		cursor: pointer;
	}
}
.CityWrap {
	display: flex;
	justify-content: center;
	align-items: center;
	.CityWrap-P:hover {
		cursor: pointer;
	}
	.CityWrap-P {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.CityWrap-M {
		display: none;
	}
}
@media screen and (max-width: 1300px) {
	.titleBar {
		padding: 0 5px !important;
	}
	.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
		display: none !important;
		height: 0 !important;
	}
}
@media screen and (max-width: 750px) {
	.titleBar {
		padding: 0 15px !important;
		margin-top: 50px !important;
	}
	.CityWrap-M {
		display: flex !important;
		justify-content: center;
		align-items: center;
	}
	.CityWrap-P {
		display: none !important;
	}
}

@media screen and (max-width: 750px) {
	.ToUp {
		right: 30px !important;
	}
	.ContentMax {
		margin-bottom: 0;
	}
}
// .swiper-button-next {
// 	right: 0 !important;
// }
.ant-breadcrumb {
	line-height: 40px !important;
}
.DevelopmentWrap .ant-tabs-nav-list .ant-tabs-tab-active a {
	color: #fc5869 !important;
}
.RecruitmentWrap .ant-tabs-nav-list .ant-tabs-tab-active a {
	color: #fc5869 !important;
}
.ContactWrap .ant-tabs-nav-list .ant-tabs-tab-active a {
	color: #fc5869 !important;
}
.ant-tabs-tab-active {
	border: none !important;
}

@media screen and (max-width: 1500px) {
	.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
		display: none !important;
		height: 0 !important;
	}
}

