.bannerBox {
	max-height: 300px;
	overflow: hidden;
	.ant-carousel .slick-slide {
		text-align: center;
		overflow: hidden;
		color: white;
		.banner-content {
			min-height: 258px;
			height: 258px;
			img {
				width: 100%;
				height: 100%;
			}
		}
	}

	.ant-carousel .slick-slide h3 {
		color: #fff;
	}

	.banner-content-h {
		background: url("../../assets/images/sy-banner/banner-bg2.png")no-repeat;
		background-size: 100% 100%;
		img {
			width: 100%;
			height: 100%;
			max-width: 100%;
			max-height: 100%;
			margin-left: -7rem;
		}
	}
	.m-hide-banner {
		img {
			display: block !important;
		}
	}
}
.ant-carousel .slick-dots{
	margin-left: 0!important;
	margin-right: 0!important;
}
.slick-dots li button:before{
	color: transparent!important;
	opacity: unset;
}
@media screen and (max-width: 2300px) {
	.banner-content-h {
		img {
			padding: 0 !important;
		}
	}
}
@media screen and (max-width: 1300px) {
	.bannerBox {
		margin-top: 40px;
		.banner-content {
			height: 300px !important;
			img {
				height: 300px !important;
			}
		}
		.banner-content-h {
			height: 300px !important;
			img {
				height: 300px !important;
			}
			//background: url("../../assets/images/m-banner2.png")no-repeat;
			//background-size: 100% 100%;
		}
	}
}
@media screen and (max-width: 750px) {
	.bannerBox {
		margin-top: 40px;
		// min-height: 300px;
		.m-hide-banner {
			img {
				display: none !important;
			}
		}
		.banner-content-h {
			height: 300px;
			background: url("../../assets/images/m-banner2.png")no-repeat;
			background-size: 100% 100%;
		}
		.banner-content {
			height: 300px !important;
			img {
				height: 300px !important;
			}
		}
	}
}
