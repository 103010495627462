.TabsBox {
	padding: 0 13rem !important;
	text-align: center;
	background: url("../../assets/images/ysjj-bg.png")no-repeat;
	.ant-tabs-nav-wrap {
		border: none;
	}
	.ant-tabs-nav .ant-tabs-tab-active {
		color: #fc5869;
		padding-bottom: 10px !important;
		border-bottom: 2px solid;
	}

	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:hover {
		color: #fc5869 !important;
		//   padding: 0 100px;
	}

	.ant-tabs-tab-btn {
		padding: 0 100px;
	}

	.ant-tabs-nav {
		width: 100%;
		color: rgba(119, 119, 119, 1) !important;

		div {
			display: flex;
			justify-content: space-around;
		}
	}

	.ant-tabs-tab {
		font-size: 20px !important;
		position: relative;
	}

	.ant-tabs-tab:hover {
		color: #fc5869 !important;
	}

	.ant-tabs-ink-bar {
		// background-color: #fc5869;
		width: 50% !important;
		position: relative;
		// bottom:1;
		// left:0;
		// z-index:0;
		// box-sizing: border-box;
		// height: 2px;
		// transform-origin:0 0;
		display: none;
	}

	.ant-tabs-ink-bars {
		transform: translate3d(100%, 0, 0) !important;
		background-color: #fc5869;
		display: none !important;
	}

	.ant-tabs-tabpane {
		.icon-s {
			width: 0;
			height: 0;
			overflow: hidden;
			font-size: 0;
			line-height: 0;
			border-width: 8px;
			border-style: solid dashed dashed dashed;
			border-color: transparent transparent #f30 transparent;
			top: 35px;
		}

		.icon-l {
			position: absolute;
			left: 25%;
		}

		.icon-r {
			position: absolute;
			right: 25%;
		}
	}

	.text-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.text-P {
			width: 205px;
		}

		.textR-P {
			span {
				white-space: nowrap;
			}
		}
	}

	h6 {
		font-size: 44px;
		padding-top: 40px !important;
		font-weight: bold;
	}

	dl {
		display: flex;
		align-items: center;

		dt {
			img {
				width: 100%;
				max-width: 100%;
				max-height: 100%;
			}
		}

		dd {
			flex-grow: 1;
			flex-shrink: 1;

			em {
				color: #fc5869;
				font-style: normal;
			}
		}
	}
}

@media screen and (max-width: 1300px) {
	.ant-tabs-nav .ant-tabs-ink-bar,
	.ant-tabs-ink-bar {
		display: none !important;
	}
	.TabsBox {
		padding: 0 30px !important;

		h6 {
			font-size: 30px;
			padding-top: 16px !important;
		}
	}
}

@media screen and (max-width: 750px) {
	.TabsBox {
		padding: 0 5px !important;

		dl {
			display: flow-root;

			dd {
				display: table-header-group !important;

				div {
					margin: 0 auto;
				}
			}

			dt {
				display: table-footer-group;

				img {
					padding: 0 15px;
				}
			}
		}

		h6 {
			font-size: 20px;
			padding-top: 16px !important;
		}

		.ant-tabs-tabpane {
			.icon-s {
				top: 25px;
			}
		}

		.ant-tabs-tab {
			font-size: 15px !important;
			width: 190px;
		}

		.text-content {
			.text-M {
				width: 267px;
			}
		}
	}
}
