a {
	color: #333333 !important;
	text-decoration: none;
}

a:hover {
	color: #fc5869;
}

.ant-popover {
	z-index: 999999999;
}

.curColor {
	color: #fc5869 !important;
}

.app-tips {
	ol {
		text-align: center;

		hr {
			color: #eeeeee;
			margin: 8px 0;
		}

		li {
			margin: 10px 0;
		}

		li:nth-child(1) {
			font-size: 16px;
		}

		li:nth-child(2) {
			color: rgba(51, 51, 51, 1);
		}

		li:nth-child(1),
		li:nth-child(4),
		li:nth-child(7) {
			color: #777777;
		}

		li:nth-child(5) {
			img {
				width: 20px;
				margin-right: 5px;
			}
		}

		li:nth-child(5),
		li:nth-child(6) {
			button {
				width: 136px;
				color: #000;
				border-color: #000000;
				outline: none;
			}
		}

		li:nth-child(6) {
			img {
				width: 20px;
				margin-right: 5px;
			}
		}

		li:last-child {
			em {
				img {
					width: auto;
					height: auto;
					max-width: 100%;
					max-height: 100%;
				}
			}

			i {
				font-style: normal;
			}
		}

		.Tips-M {
			display: none;

			ul {
				display: -webkit-box;
				display: -ms-flexbox;
				display: -webkit-flex;
				display: flex;
				-webkit-box-orient: vertical;
				-ms-flex-direction: column;
				-webkit-flex-direction: column;
				flex-direction: column;

				li {
					img {
						width: 20px;
						margin-right: 5px;
					}

					button {
						width: 136px;
						color: #000;
						border-color: #000000;
						outline: none;
					}
				}
			}

			div {
				display: flex;
				flex-direction: column;

				em {
					img {
						padding: 20px;
						width: auto;
						height: auto;
						max-width: 100%;
						max-height: 100%;
					}
				}

				i {
					font-style: normal;
				}
			}
		}

		.Tips-P {
			display: block;

			ul {
				display: flex;
				flex-direction: column;

				li {
					img {
						width: 20px;
						margin-right: 5px;
					}

					button {
						width: 136px;
						color: #000;
						border-color: #000000;
						outline: none;
					}
				}
			}

			.EQ-content {
				dl:nth-child(1) {
					display: block;

					em {
						img {
							width: auto;
							height: auto;
							max-width: 100%;
							max-height: 100%;
						}
					}
				}

				dl:nth-child(2),
				dl:nth-child(3) {
					display: none;
				}
			}
		}
	}
}

.isShow {
	display: none !important;
}

.header {
	width: 100%;
	height: 60px;
	position: fixed;
	top: 0;
	background: white;
	z-index: 99999;
	padding: 0 13rem 0 13rem !important;
	display: flex;
	align-items: center;

	.header-wrap {
		width: 100%;
		position: relative;

		.bannerMark {
			position: absolute;
			top: 60px;
			left: 0;
			width: 100%;
			height: 300px;
			background: rgba(0, 0, 0, .5);
			z-index: 99999;
			display: none;
		}

		h1,
		p {
			margin-bottom: 0 !important;
		}

		.left-title {
			width: 100%;
			display: flex;
			justify-content: space-between;

			div:hover {
				cursor: pointer;
			}

			div {
				span {
					font-size: 16px;
				}
			}

			.left-img {
				display: inline-block;
				width: 90px;
				height: 30px;
				background: url('../../assets/images/logo1.png') no-repeat;
				background-size: 100%;
			}

			div {
				display: flex;
				align-items: center;

				div {
					display: flex;
					align-items: center;
				}

				span:nth-child(1) {
					width: 20px;
					height: 2rem;
					display: inline-block;
					background: url("../../assets/images/手机.png") no-repeat;
					margin-right: 6px;
				}
			}
		}

		.right-title {
			position: absolute;
			top: 9px;
			ul {
				li:hover {
					color: #fc5869 !important;
				}
			}

			//top: 20px;
			margin-left: 130px;

			.curActiveCss {
				color: #fc5869 !important;
			}

			.ant-radio-wrapper-checked {
				span {
					color: #fc5869 !important;
				}
			}

			.ant-radio {
				display: none !important;
			}

			ul {
				display: -webkit-box;
				display: -ms-flexbox;
				display: -webkit-flex;
				display: flex;
				padding: 0 !important;
				margin: 0 !important;

				li {
					flex-grow: 1;
					white-space: nowrap;
					text-align: center;
					margin-right: 48px;
					cursor: pointer;
					color: #333333;
					font-size: 16px;
					font-weight: 600;

					a {
						font-size: 16px;
					}

					a:hover {
						color: #fc5869 !important;
					}

					a:active {
						color: #fc5869 !important;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1300px) {
	.header {
		padding: 0 30px !important;
		height: 60px !important;

		.right-title {
			margin-left: 0;
			top: 15px;

			.isShow {
				display: block;
			}

			ul {
				li {
					margin-right: 20px;
				}
			}
		}
	}
}

@media screen and (max-width: 750px) {
	.header {
		padding: 5px 15px 0 15px !important;
		height: 100px !important;
		align-items: start;

		.left-title {
			div {
				span {
					font-size: 13px !important;
				}

				span:nth-child(2) {
					padding-right: 10px;
				}
			}
		}

		.bannerMark {
			display: none !important;
		}

		.right-title {
			margin-left: 0 !important;
			top: 60px !important;

			.isShow {
				display: block !important;
			}

			ul {
				li {
					margin-right: 20px !important;
				}
			}
		}
	}

	.app-tips {
		.Tips-P {
			display: none !important;
		}

		.Tips-M {
			display: block !important;
		}
	}
}
