.RecruitmentWrap .ant-tabs-nav-list .ant-tabs-tab, .DevelopmentWrap  .ant-tabs-nav-list .ant-tabs-tab, .ContactWrap .ant-tabs-nav-list .ant-tabs-tab{
	border-right: 1px solid #cccccc!important;
}

.Company-wrap {
	padding: 0 13rem;
	border: 0;
	.ant-tabs-tab-active {
		border-right: 1px solid #cccccc!important;
		a {
			color: #fc5869 !important;
		}
	}
	.ant-tabs-nav::before {
		border: 0 !important;
	}
	.ant-tabs-nav-list {
		.ant-tabs-tab {
			border-right: 1px solid #cccccc!important;
			margin: 20px 0 0 0 !important;
			padding: 0 10px !important;
		}

		.ant-tabs-tab:first-child {
			padding-left: 0 !important;
		}

		.ant-tabs-tab:nth-of-type(4) {
			border-right: none!important;
		}

		.ant-tabs-nav .ant-tabs-tab-active {
			a {
				color: #fc5869 !important;
			}
		}

		.ant-tabs-tab:hover {
			a {
				color: #fc5869 !important;
			}
		}

		.ant-tabs-ink-bar {
			display: none !important;
		}
	}

	.CompanyWrap-content-title-info {
		font-size: 16px;

		p {
			color: rgba(119, 119, 119, 1);
		}
	}

	.CompanyWrap-content-info {
		padding: 0 5rem;

		ul {
			li {
				margin: 10px 0;
			}

			li:nth-child(1) {
				text-align: center;
				background: #f5f5f5;
				padding: 20px 0;
				font-size: 18px;
				display: flex;
				flex-direction: column;

				em {
					color: #d60812;
					font-size: 24px;
					font-style: normal;
				}
			}

			li:nth-child(2) {
				img {
					width: 100%;
				}
			}
		}
	}

	.CompanyWrap-content-footer-text {
		margin: 40px 0;
		color: rgba(119, 119, 119, 1);
	}
}

@media screen and (max-width: 1300px) {
	.Company-wrap {
		padding: 0 10px;
	}
}

@media screen and (max-width: 750px) {
	.Company-wrap {
		padding: 0 5px;

		.CompanyWrap-content-info {
			padding: 0 10px;

			ul {
				display: flex;
				flex-direction: column;

				li {
					span {
						display: flex;
						flex-direction: column;
						font-size: 13px;

						i,
						em {
							font-style: normal;
						}
					}
				}

				li:nth-child(1) {
					em {
						font-size: 15px !important;
					}
				}
			}
		}
	}
}
