
.Activity-Details-wrap{
  padding: 0 13rem!important;
  h6{
    border-bottom: 1px solid #cccccc;
    padding: 18px 0 12px 0!important;
    font-size: 18px;
    font-size: 16px;
    font-weight: bold;
  }
  .Activity-content{
    div{
      p:nth-child(1){
        font-size: 28px;
        text-align: center;
        font-weight: bold;
      }
      p:nth-child(2){
        display: flex;
        justify-content: space-between;
        span{
          em{
            font-style: normal;
            i{
              img{
                width: 15px;
                height: 15px;
                max-width: 100%;
                max-height: 100%;
              }
            }
          }
        }
        span:nth-child(2){
          em{
            margin: 0 5px;
          }
        }
      }
    }
    .Activity-content-pic{
      margin-bottom: 50px!important;
      img{
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .Activity-Details-wrap{
    padding: 0 5px!important;
    h6{
      font-weight: normal;
      font-size: 15px;
    }
    .Activity-content{
      div{
        p:nth-child(1){
          font-size: 18px;
        }
        p:nth-child(2){
          display: flex;
          flex-direction: column;
          span{
            margin: 8px!important;
          }
        }
      }
    }
  }
}

