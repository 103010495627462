@import '~antd/dist/antd.css';

ol,
ul {
	list-style: none !important;
}
.ant-tabs-nav .ant-tabs-tab {
	margin: 0 !important;
}
* {
	margin: 0;
	padding: 0;
}
.Main {
	display: flex;
	flex-direction: column;

	.mainContent {
		flex: 1;
		height: 100%;
		margin-top: 100px;
	}
	.goUp {
		position: fixed;
		bottom: 165px;
		right: 10px;
		width: 44px;
		height: 44px;
		z-index: 999999;
		background-color: #fc5869;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: #ffffff;

		em {
			width: 16px;
			height: 16px;
			display: block;
			background: url("../../assets/images/top.png") no-repeat;
		}
	}
}

@media screen and (max-width: 750px) {
	.isShow {
		display: block;
	}
	.goUp {
		right: 10px !important;
		width: 30px !important;
		height: 30px !important;
	}
	.footer {
		display: flow-root !important;
		text-align: center;
		justify-content: center;
		padding: 0 5px !important;

		.footer-logo {
			margin: 10px auto !important;
		}

		.footer-list {
			ul {
				justify-content: center !important;
			}
		}

		.footer-Authentication {
			ul {
				display: flow-root !important;
			}
		}

		.footer-ERcode {
			div {
				display: flex;
				justify-content: center !important;
				margin: 0 auto !important;
			}
		}
	}
}
