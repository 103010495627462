.Acti-wrap {
	.Detail-bg {
		height: 320px;
		background: #cccccc;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		dl {
			width: 100%;
			dt {
				// padding: 10px 0;
				width: 200px;
				height: 280px;
				img {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
	.titleBar {
		margin-top: 15px !important;
		span {
			color: #777777;
		}
	}

	.ActivityWarp {
		padding: 0 13rem !important;
		.ActivityWarp-title {
			margin-left: 10px;
			margin-top: 45px;
			border-bottom: 1px solid #eeeeee;
			a {
				margin-right: 10px;
				font-size: 20px;
				color: #777777;
				font-weight: bold;
			}
			a:first-child {
				color: #fc5869 !important;
			}
			a:last-child {
				margin-left: 10px;
			}
			a:hover {
				color: #fc5869 !important;
			}
			a:active {
				color: #fc5869 !important;
			}
		}
		.ant-anchor-ink::before {
			width: 0;
		}

		.ant-anchor {
			display: flex;
			border-bottom: 1px solid #eeeeee;

			div:nth-child(2) {
				padding-right: 10px !important;
			}
			div:nth-child(4) {
				padding-left: 10px !important;
			}

			.ant-anchor-link {
				padding: 20px 0;

				.ant-anchor-link-title:hover {
					color: #fc5869 !important;
				}
			}
		}
	}

	.ant-tabs-bar {
		//border: 0;
		.ant-tabs-nav .ant-tabs-tab-active {
			color: #fc5869;
		}

		.ant-tabs-tab {
			border-right: 1px solid #cccccc;
			padding: 0 10px 0 0 !important;
			margin: 10px 10px 10px 0 !important;
			color: rgba(119, 119, 119, 1) !important;
		}

		.ant-tabs-tab:last-child {
			border: 0;
		}

		.ant-tabs-nav .ant-tabs-tab-active {
			color: #fc5869;
		}

		.ant-tabs-tab:hover {
			color: #fc5869;
		}

		.ant-tabs-ink-bar {
			display: none !important;
		}
	}
	.swiper-button-prev,
	.swiper-button-next {
		background-color: #fc5869;
		background-size: 50%;
		color: #ffffff;
		// width: 34px;
		width: 30px;
		height: 73px;
		top: 36%;
	}

	.Detail-bg {
		padding: 0 13rem;
		z-index: 999;
		position: relative;

		.vidoeMark {
			background-repeat: no-repeat !important;
			background-size: cover !important;
			background-position: 0 30% !important;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			filter: blur(14px);
			.mark {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: rgba(0, 0, 0, .5);
			}
		}
		dl {
			display: flex;
			align-items: center;
			margin-bottom: 0 !important;
			z-index: 12;
			position: relative;
			// dt {
			// 	img {
			// 		width: 100%;
			// 	}
			// }
			.detail-right-box{
				padding: 0 20px;
				.product-name{
					color: #ffffff;
					font-size: 24px;
					font-weight: 600;
				}
				.row-two-box{
					display: flex;
					align-items: center;
					margin: 5px 0 10px;
					.yellow-text{
						color: #feae04;
						font-size: 20px;
					}
					.white-text{
						color: #fff;
						font-size: 20px;
					}
					.white-line{
						color: #fff;
						margin: 0 15px 0 5px;
					}
				}
				.move-type{
					margin-right: 10px;
					font-size: 12px;
					display: inline-block;
					margin-bottom: 10px;
					.type-left{
						padding: 3px 5px;
						background: #9392A7;
						color: #fff;
						border-radius: 3px 0 0 3px;
					}
					.type-right{
						padding: 3px 5px;
						background: #FAFBFA;
						color: #9F9EB1;
						border-radius:0 3px 3px 0;
					}
				}
			}
			.row-three-box{
				margin: 5px 0 15px;
				.desc{
					padding:2px 5px;
					background: #FAFBFA;
					color:#807FA2;
					border-radius: 3px ;
				}
			}
			.row-four-box{
				color: #fff;
				display: flex;
				align-items: center;
				margin-bottom: 5px;
				span{
					min-width: 100px;
				}
			}
		}
	}

	.Detail-Text {
		ul {
			li {
				margin: 10px 0 !important;
				color: #222222;
				i,
				em {
					font-style: normal;
					font-weight: bold;
				}
				span {
					color: #abafbb;
				}
			}
			li:nth-child(4) {
				span {
					color: #777777;
				}
			}

			li:first-child {
				padding-top: 20px;
				p {
					font-size: 18px;
					border-left: 3px solid #fc5869;
					padding-left: 5px !important;
					font-weight: bold;
				}
			}
		}
	}

	.Detail-Img {
		margin-bottom: 30px;
		h6 {
			border-left: 3px solid #fc5869;
			font-size: 18px;
			font-weight: normal;
			padding-left: 5px;
			font-weight: bold;
		}

		.ActivityWarp-react-content {
			height: 280px;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			img {
				//width: 100%;
				height: 100%;
				text-align: center;
			}
		}
		.swiper-container {
			height: 110px;
			--swiper-navigation-color: #fff !important; /* 单独设置按钮颜色 */
		}
		.swiper-img {
			img {
				height: 100% !important;
			}
		}
		.ActivityWarp-pic {
			height: 110px;
			margin: 0 auto;
			div {
				overflow: hidden;
				p {
					margin-right: 10px;
					cursor: pointer;
					img {
						height: 100%;
					}
				}
			}
		}
		.swiper-img {
			margin-top: 20px;
			img {
				height: 100%;
			}
		}
	}
}

@media screen and (max-width: 1300px) {
	.Acti-wrap {
		.ActivityWarp {
			padding: 0 10px !important;
		}
	}
}

@media screen and (max-width: 750px) {
	.product-name{
		font-size: 19px!important;
		margin-top: 20px;
	}
	.yellow-text, .white-text{
		font-size: 14px!important;
	}
	.Acti-wrap {
		.m-titlBar {
			margin-top: 40px !important;
		}
		.ActivityWarp {
			padding: 0 5px !important;
		}

		.Detail-bg {
			padding: 0 5px !important;
			margin-top: 43px !important;
			background-size: cover;

			dl {
				display: flow-root;

				dt {
					text-align: center;
					width: 150px;
					height: 200px;
					img {
						width: 50%;
					}
				}

				dd {
					text-align: left;

					ul {
						display: flow-root;

						li:nth-child(3) {
							flex-wrap: wrap;

							p {
								width: 50%;
							}
						}
					}
				}
			}
		}
		.Detail-bg {
			height: auto !important;
			margin: 0 auto;
			dl {
				width: 100%;
				display: flex;
				flex-direction: column;
				dt {
					img {
						width: 100%;
						margin: 10px 0;
					}
				}
				dd {
					width: 100%;
					margin-left: 15px !important;
					div:nth-child(2) {
						padding: 0;
						p {
							align-items: center;
						}
					}
				}
			}
			div:first-child {
				// margin-top: 15px;
			}
			div:nth-child(2) {
				p:nth-child(1) {
					display: flex;
				}
			}
		}
		.swiper-img {
			height: 100px !important;
		}
		.Detail-Text {
			ul {
				display: flow-root;

				li {
					margin: 10px !important;
				}
			}
		}

		.m-hide,
		.m-titlBar {
			display: none !important;
		}
	}
}
