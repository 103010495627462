.site-card-wrapper {
	padding: 20px 13rem !important;
	background: rgba(245, 245, 245, 1);
	min-height: 314px;
	.site-card-wrapper-title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-top: 30px;
		h6 {
			font-size: 20px;
			font-weight: bold;
		}
		span {
			font-size: 16px;
			a {
				color: rgba(153, 153, 153, 1) !important;
			}
			a:hover {
				color: #fc5869 !important;
			}
		}
	}

	ul {
		//display: grid;
		//grid-template-columns: auto auto auto auto;
		padding: 0 !important;
		display: flex;
		li {
			width: 25%;
			margin-right: 10px !important;
		}
	}

	.site-card-wrapper-slid:hover {
		.time {
			transform: translateY(-30px);
			transform-style: preserve-3d;
			transition: transform 1s;
			opacity: 1;
		}
	}

	.site-card-wrapper-slid {
		position: relative;
		img {
			width: 100%;
			height: 136px;
			max-width: 100%;
			max-height: 100%;
		}

		em {
			font-style: normal;
			background: white;
			display: block;
			padding: 12px 0 12px 15px !important;
			position: relative;
			z-index: 999;
			font-size: 16px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			color: #333333;
		}

		.time {
			width: 100%;
			height: 30px;
			color: #ffffff;
			line-height: 30px;
			padding-left: 10px !important;
			position: absolute;
			bottom: 15px;
			background: rgba(0, 0, 0, .5);
			opacity: 0;
			z-index: 1;
		}
	}
}
@media screen and (max-width: 1300px) {
	.site-card-wrapper {
		padding: 20PX 30PX !important;
	}
}

@media screen and (max-width: 750px) {
	.site-card-wrapper {
		padding: 0 5px !important;
		min-height: 360px;
		.site-card-wrapper-title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-top: 10px;
			h6 {
				font-size: 15px;
				margin-left: 10px;
			}
			span {
				font-size: 13px;
			}
		}
	}
	.card-m {
		flex-wrap: wrap;
		li {
			width: 50% !important;
			margin: 0 !important;
		}
	}
	.site-card-wrapper ul li {
		margin-right: 0 !important;
		padding: 10px;
	}
	.site-card-wrapper-slid {
		pointer-events: none;
		img {
			display: inline-block;
			width: 100%;
			max-width: 100%;
			max-height: 100%;
		}
		em {
			font-size: 13px !important;
		}
	}
}
