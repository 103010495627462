.bigimg {
	margin: 0 auto 30px auto;
	width: 470px;
	height: 250px;
	border-radius: 5px;
	.img {
		width: 100%;
		height: 100%;
		border-radius: 5px;
	}
}
.swiper-wrap-box{
	position: relative;
		.dy-img-box{
			overflow: hidden;
			height: 140px;
			.img{
				height: 140px;
				width: 100%;
				margin: 0 10px;
			}
		}
		.slider-btn{
			background: #fc5869;
			position: absolute;
			top: 50px;
			color: #fff;
			width: 24px;
			line-height: 40px;
			text-align: center;
		}
		.left-slider-btn{
			left: 10px;
		}
		.right-slider-btn{
			right: 0px;
		}
}

@media screen and (max-width: 750px) {
	.swiper-wrap-box{
		.dy-img-box{
			height: 100px;
			.img{
				height: 100px;
				width: 100%;
				margin: 0 10px;
			}
		}
		.slider-btn {
			display: none;
		}
	}
	
}

